<template>
    <div>
        <div ref="cardElement"></div>
        <div v-if="cardAuthActive" ref="authContainer"></div>
    </div>
</template>

<script setup lang="ts">
import externalScript from "@iris/externalScript";

import type {
  Address,
  CardElement,
  ElementsInstance,
  Recurly,
  TokenPayload,
} from "@recurly/recurly-js";


export interface RecurlyFieldChangeResult {
  empty: boolean;
  focus: boolean;
  valid: boolean;
}

export interface RecurlyChangeResult extends RecurlyFieldChangeResult {
  firstSix: string;
  lastFour: string;
  brand: string;
  number: RecurlyFieldChangeResult;
  expiry: RecurlyFieldChangeResult;
  cvv: RecurlyFieldChangeResult;
}


    const emits = defineEmits<{
        /** card data is valid and ready to be tokenised */
        (event: "valid", value: boolean): void;
    }>();

    const props = defineProps<{
        /** recurly token info */
        tokenInfo: Address;

    }>();

    const config = useRuntimeConfig();

const cardElement = ref<HTMLElement | null>();
const authContainer = ref<HTMLElement | null>();
const cardAuthActive = ref(false);

const element = ref<CardElement | undefined>();
const elementsInstance = ref<ElementsInstance | undefined>();

const recurlyData = ref<RecurlyChangeResult>();

const recurly = ref<Recurly>();


/** get recurly token */
const token = () => {
  return new Promise<TokenPayload>((resolve, reject) => {
    // overloads as want same return type as input type
    recurly.value.token(elementsInstance.value!, props.tokenInfo, (err, arg) => {
      return err ? reject(err) : resolve(arg);
    });
  });
}

/** get card auth as required */
const cardAuth = (actionTokenId: string) => {
    return new Promise<{
              id: string;
              type: string;
            }>((resolve, reject) => {
                cardAuthActive.value = true
              const risk = recurly.value.Risk();
              const threeDSecure = risk.ThreeDSecure({ actionTokenId });
              threeDSecure.attach(authContainer.value!);
              threeDSecure.on("error", reject);
              threeDSecure.on("token", resolve);
            }).then(v => {
                cardAuthActive.value = false;
                return v;
            }).catch(e => {
                cardAuthActive.value = false;
                throw e;
            })
}

defineExpose({
        token,
        cardAuth
})

watchEffect(() => {
    emits('valid', recurlyData.value?.valid)
})


onMounted(() => {
    return (recurly.value ? Promise.resolve(recurly.value) : externalScript<Recurly>(
    "https://js.recurly.com/v4/recurly.js",
    "recurly",
    false
  )).then((r) => {
    recurly.value = r
    recurly.value.configure({
      publicKey: config.public.recurlyPublicKey,
    });
    elementsInstance.value = recurly.value.Elements();
    element.value = elementsInstance.value.CardElement({
        style: {
            lineHeight: '24px',
            placeholder: {
            color: '#495057'
            }
        }
    });
    element.value.attach(cardElement.value);
    element.value.on("change", (state: RecurlyChangeResult) => {
        recurlyData.value = state;
        });
    })

})

onUnmounted(() => {
    if (element.value) {
                element.value.remove()
                element.value.off()
                element.value = undefined
            }
})

</script>